/*
This plugin creates the doctor search results list button from the searchId on the page and launches the print.html window.

USAGE: The following defaults are applied automatically, but can be overwritten if necessary

$(".DrResults").searchIdPrintResults({
	topLink: '#DrResults .SortContainer',				//The location to put the top button (uses jQuery before function).
														//(for the basic search, the markup may need to be: '#DrResults #ProviderBrowse > .SortContainer')
	bottomLink: '#DrResults',							//The location to put the bottom button (uses jQuery append function)
	buttonName: 'Print Doctor Search Results List',		//The name to appear on the button
	showAsButton: 'no'									//Specify if the link should show as a button. Default is no. if so, use: showAsButton: 'yes'
	windowHeight: 800,									//The height of the print.html window
	windowWidth: 800,									//The width of the print.html window
	windowScrollbar: 'yes'								//Should the window have scrollbars?
});

Don't want to change any defaults? Just use this:
$(".DrResults").searchIdPrintResults();

*/

(function($) {
	$.fn.searchIdPrintResults = function(options) {
		$.fn.searchIdPrintResults.defaults = {
			topLink: '#DrResults > .SortContainer',		
			bottomLink: '#DrResults',					
			buttonName: 'Print Doctor Search Results List',	
			showAsButton: 'no',	
			windowHeight: 800,									
			windowWidth: 800,									
			windowScrollbar: 'yes'								
		};
		options = $.extend({}, $.fn.searchIdPrintResults.defaults, options);
		
		// get the searchId from the vitalSite object on the page and use that for the search
        if (("SearchData" in VitalSite) && VitalSite.SearchData !== null && VitalSite.SearchData.SearchInfo !== null) {
            var path = '<div class="printLink"><a href="/html/print.html?SearchId=' + VitalSite.SearchData.SearchInfo.SearchId + '&Sort=' + $.url.param('sort') + '" class="PrintResults">'+ options.buttonName +'</a></div>';
            //$(options.topLink).before(path);
            $(options.bottomLink).append(path);

            options.showAsButton !== 'no'? $('.printLink a.PrintResults').addClass("Button") : '';
            // Open the print.html page
            $('a.PrintResults').on('click', function () {
                var newwindow = window.open($(this).attr('href'), 'name', 'height='+ options.windowHeight +',width='+ options.windowWidth +',scrollbars=' + options.windowScrollbar);
                if (window.focus) { newwindow.focus(); }
                return false;
            });
        }
	};
}(jQuery));